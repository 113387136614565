import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { QosPlugin } from '@microsoft/1ds-qos-js';
import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';

import resourceRoutes from '~/constants/resourceRoutes';

import type { IExtendedConfiguration, IExtendedTelemetryItem } from '@microsoft/1ds-analytics-web-js';
import type { User } from 'types';

export const appInsights = new ApplicationInsights();
const qosPlugin = new QosPlugin();

export const useAppInsightsInitialiser = (clientKey: string, environment: string, user: User) => {
  const fetcher = useFetcher<string | undefined>();
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const [completedSessionIdCall, setCompletedSessionIdCall] = useState(false);

  const sanitizeUrl = (url: string) => {
    const sanitizedUrl = new URL(url);
    const bannedKeys = ['token', 'signature', 'sig', 'credential', 'key'];

    sanitizedUrl.searchParams.forEach((_, key) => {
      if (bannedKeys.some(sensitiveKey => key.toLowerCase().includes(sensitiveKey))) {
        sanitizedUrl.searchParams.set(key, '<redacted>');
      }
    });

    return sanitizedUrl.toString();
  };

  useEffect(() => {
    if (completedSessionIdCall && fetcher.state === 'idle') fetcher.load(resourceRoutes.aiCookie);
  }, [fetcher, completedSessionIdCall]);

  useEffect(() => {
    if (fetcher.type === 'done') {
      setCompletedSessionIdCall(true);
      setSessionId(fetcher.data);
    }
  }, [fetcher]);

  if (appInsights.isInitialized()) return;

  const config: IExtendedConfiguration = {
    instrumentationKey: clientKey,
    channelConfiguration: {
      // Post channel configuration
      eventsLimitInMem: 50,
    },
    propertyConfiguration: {
      // Properties Plugin configuration
      env: environment === 'prod' ? 'PROD' : 'PPE',
      enableDistributedTracing: true,
      enableApplicationInsightsTrace: true,
    },
    webAnalyticsConfiguration: {
      // Web Analytics Plugin configuration
      autoCapture: {
        scroll: true,
        pageView: true,
        onLoad: true,
        onUnload: true,
        click: true,
        resize: true,
        jsError: true,
      },
      urlCollectQuery: true,
      shareAuthStatus: true,
      isLoggedIn: user && user.id !== undefined,
    },
    extensions: [qosPlugin],
    extensionConfig: {
      [qosPlugin.identifier]: {
        disableAjaxTracking: false,
        // Don't track calls to New Relic or the Snap APIs
        excludeRequestFromAutoTrackingPatterns: [
          '^https://bam.nr-data.net/',
          '^https://aws.api.snapchat.com',
          '^https://api-kit.snapchat.com',
          '^https://camera-kit-api.snapar.com/',
        ],
      },
    },
  };

  appInsights.initialize(config, []);

  if (user && user.id !== undefined) {
    appInsights.getPropertyManager().getPropertiesContext().user.localId = `c:${user.id}`;
  }

  if (sessionId) appInsights.getPropertyManager().getPropertiesContext().session.setId(sessionId);

  appInsights.addTelemetryInitializer((envelope: IExtendedTelemetryItem) => {
    const { baseData } = envelope;
  
    const isOutgoingRequest = baseData && envelope?.name?.toLowerCase?.().includes('outgoing'); // Ms.Web.OutgoingRequest / outgoing_request
    if (isOutgoingRequest) {
      if (baseData.properties) {
        baseData.name = (baseData.properties as { HttpMethod: string }).HttpMethod ?? 'Unknown"';
      }
  
      baseData.target = sanitizeUrl(baseData.target as string);
    }
  });

  return appInsights;
};