import FileSaver from 'file-saver';
import { t } from 'i18next';
import { upperFirst } from 'lodash-es';
import { lazy } from 'react';

import { appInsights } from './appInsightsInitializerHelper';
import { getFullDate } from './dateHelpers';
import resourceRoutes from '~/constants/resourceRoutes';

import type { IExtendedTelemetryItem } from '@microsoft/1ds-analytics-web-js';
import type { Segment } from '@onecamera/core';
import type { FetcherWithComponents } from '@remix-run/react';
import type { Comment, FeatureFlag, FlipResponse, Member, MixtapeResponse, TelemetryType, User } from 'types';

export const isFlagPresent = (featureFlags: FeatureFlag[], flagName: string) => {
  return featureFlags && featureFlags.some(obj => obj.name === flagName);
};

export const scrollToTop = () => {
  if (typeof document !== 'undefined') {
    try {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } catch (err) {
      window.scrollTo(0, 0);
    }
  }
};

export const telemetryAttributes = (data: TelemetryType) => {
  return { 'data-m': JSON.stringify(data) };
};

export const isString = (str: string) => {
  return typeof str === 'string';
};

export const isValidUrl = (str: string) => {
  // Regex founder here: https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
  const pattern = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  return pattern.test(str);
};

export const filenameVideo = (item: FlipResponse | Comment | MixtapeResponse) => {
  return `${t('common.video')} ${`${item.display_name || item.last_name || ''}`} ${getFullDate(item.created_at)}`
    .replace(/[.,]/g, '')
    .replace(/[^a-zA-Z0-9\s]/g, '_');
};

export const filenameImg = (item: FlipResponse | Comment | MixtapeResponse) => {
  return `${t('common.coverImage')} ${`${item.display_name || item.last_name || ''}`} ${getFullDate(item.created_at)}`
    .replace(/[.,]/g, '')
    .replace(/[^a-zA-Z0-9\s]/g, '_');
};

export const parseStringifiedFormData = (formData: AnyObj) => {
  const formDataToJsonParse: string[] = formData.fieldsToParse && JSON.parse(formData.fieldsToParse as string);
  if (!formDataToJsonParse || !Array.isArray(formDataToJsonParse)) return;

  formDataToJsonParse.forEach(property => {
    formData[property] = JSON.parse(formData[property] as string);
  });
};

export const getMemberDisplayMeta = (member: Member, membership: Member | null) => {
  const isInvitedUser = !member.first_name;
  const isRequestingToJoin = member.role === 'requested';
  const isCurrentUserGroupOwner = membership?.role === 'owner' || membership?.role === 'co_owner';

  if (member.role === 'requested') return member.email;
  if (isInvitedUser) return t('common.invited');
  if (member.role === 'owner') return t('common.educator');
  if (member.role === 'co_owner') return t('common.colead');
  if (member.role === 'member' || (!isCurrentUserGroupOwner && member.role === 'pending')) return t('common.student');
  return `${upperFirst(member.role)}${member.email && !isRequestingToJoin ? ` • ${member.email}` : ''}`;
};

export const responseTooltipText = (response: FlipResponse, isGroupLead: boolean, user: User) => {
  const userOwnsResponse = response.user_id === user.id || response.student_id === user.id;
  if (isGroupLead && userOwnsResponse) return t('shared.onlyYouCanView');
  if (isGroupLead && !userOwnsResponse) return t('shared.onlyYouAndOwnerCanView');
  if (!isGroupLead && userOwnsResponse) return t('shared.onlyYouAndYourLeadCanView');
  return '';
};

export const removeHttpsFromUrl = (url: string) => {
  return url.replace(/(^\w+:|^)\/\//, '');
};

/**
 * Logs an event to Application Insights.
 * This function is intended for client-side logging only.
 */
export const logEvent = (event: IExtendedTelemetryItem, customProperties?: unknown) => {
  if (appInsights && appInsights.isInitialized()) appInsights.trackEvent(event, customProperties);
};

export const signOut = (fetcher: FetcherWithComponents<unknown>) => {
  // Delete IndexedDB so all user data is erased before signing out
  indexedDB.deleteDatabase('Flip_Camera_SessionData');

  fetcher.submit(null, { action: resourceRoutes.signOut, method: 'post' });
};

export const getReadableTime = (lengthInSeconds: number) => {
  let time;

  if (lengthInSeconds < 60) time = t('helper.numSeconds', { count: lengthInSeconds });
  else if (lengthInSeconds < 60 * 60) {
    const minutes = Math.round(lengthInSeconds / 60);
    time = t('helper.numMinutes', { count: minutes });
  } else if (lengthInSeconds < 60 * 60 * 24) {
    const hours = Math.round(lengthInSeconds / (60 * 60));
    time = t('helper.numHours', { count: hours });
  } else if (lengthInSeconds < 60 * 60 * 24 * 7) {
    const days = Math.round(lengthInSeconds / (60 * 60 * 24));
    time = t('helper.numDays', { count: days });
  } else if (lengthInSeconds < 60 * 60 * 24 * 30.4375) {
    const weeks = Math.round(lengthInSeconds / (60 * 60 * 24 * 7));
    time = t('helper.numWeeks', { count: weeks });
  } else if (lengthInSeconds < 60 * 60 * 24 * 365.25) {
    const months = Math.round(lengthInSeconds / (60 * 60 * 24 * 30.4375));
    time = t('helper.numMonths', { count: months });
  } else {
    const years = Math.round(lengthInSeconds / (60 * 60 * 24 * 365.25));
    time = t('helper.numYears', { count: years });
  }

  return time;
};

// credit: https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
export const lazyLoadWithRetries: typeof lazy = importer => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error: TSFix) {
      // we need to get the specific build url of the camera module we are importing, and update it with a query parameter so that the browser does not cache it.
      // this retry fix assumes that the error looks like: eg. "Failed to fetch dynamically imported module: https://flip.com/build/_shared/src-KEEZ244S.js"
      // if the error does not look like this, this method will no longer work.
      const isValidErrorStr = error?.message?.includes('Failed to fetch dynamically imported module');
      if (!isValidErrorStr) return;

      // retry 3 times with 2 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
      for (let i = 0; i < 3; i++) {
        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
        await new Promise(resolve => setTimeout(resolve, 1000 * 2 ** i));

        const url = new URL(error.message.replace('Failed to fetch dynamically imported module: ', '').trim());
        // add a timestamp to the url to force a reload the module (and not uno-await-in-loopse the cached version - cache busting)
        url.searchParams.set('t', `${+new Date()}`);

        try {
          // @ts-ignore
          // eslint-disable-next-line no-await-in-loop, no-unsanitized/method
          return await import(url.href);
        } catch (e) {
          console.log('retrying import', i);
        }
      }
      logEvent(
        { name: 'CameraImport::Error' },
        {
          error: error?.message ?? '',
          fullErr: JSON.stringify(error ?? {}),
          customMsg: 'Failed to load camera',
        },
      );
      throw error;
    }
  };
  return lazy(retryImport);
};

export const downloadVideoFromRecorderSegments = (segments: Segment[]) => {
  segments.map((segment, index) =>
    FileSaver.saveAs(segment.videoBlob, t('shared.responseFileNameNum', { num: index })),
  );
};

export const shouldDisplayVideoResponseDetailsLink = (pageEntity: string) => {
  const responsePageEntitiesWithoutDetails = ['TopicFocus', 'Comment'];
  return !responsePageEntitiesWithoutDetails.includes(pageEntity);
};
